import React, { useMemo } from 'react';
import { useNavigation } from '../../contexts/NavigationContext';
import { blogMetadataList } from '../../data/blogMetadata'; // Import the new metadata list
import { MainBlogPost } from './MainBlogPost';
import { Calendar } from 'lucide-react';
import { PageName } from '../../utils/router';
import { BlogMetadata } from '../../types/blog';

export function BlogList() {
  const { navigate } = useNavigation();
  
  // Sort blog posts by date (oldest to newest)
  const sortedBlogPosts = useMemo(() => {
    return [...blogMetadataList].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA.getTime() - dateB.getTime();
    });
  }, []);

  // Get the oldest post for the main feature
  const oldestPost = sortedBlogPosts[0];

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      {/* Main Article (oldest post) */}
      <MainBlogPost post={oldestPost} />

      {/* More Articles */}
      <h2 className="text-2xl font-bold text-gray-900 mb-8">More Articles</h2>
      <div className="grid gap-8 md:grid-cols-3">
        {/* Display the rest of the posts (excluding the main/oldest one) */}
        {sortedBlogPosts.slice(1).map((meta: BlogMetadata) => ( 
          <article 
            key={meta.id}
            onClick={() => navigate(meta.page as PageName)} // Use meta.page for navigation
            className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          >
            <img
              className="h-48 w-full object-cover"
              src={meta.image}
              alt={meta.title}
            />
            <div className="p-6">
              <div className="text-sm font-medium text-emerald-600 bg-emerald-50 inline-block px-3 py-1 rounded-full">
                {meta.category}
              </div>
              <h3 className="mt-2 text-lg font-semibold text-gray-900 hover:text-emerald-600 transition-colors duration-200">
                {meta.title}
              </h3>
              <p className="mt-2 text-sm text-gray-600 line-clamp-2">{meta.excerpt}</p>
              <div className="mt-4 flex items-center text-sm text-gray-500">
                <Calendar className="h-4 w-4 mr-1" />
                <span>{meta.date}</span>
              </div>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
}
