import React, { useMemo } from 'react';
import { useNavigation } from '../contexts/NavigationContext';
import { blogMetadataList } from '../data/blogMetadata'; // Import the dynamic metadata
import { BlogMetadata } from '../types/blog'; // Import the BlogMetadata interface
import { PageName } from '../utils/router'; // Import the PageName type

export function RecentArticles() {
  const { navigate } = useNavigation();
  
  // Get the 3 most recent articles by sorting by date (newest first)
  const recentArticles = useMemo(() => {
    // Create a copy of the blog metadata list to avoid modifying the original
    return [...blogMetadataList]
      // Sort by date (newest first)
      .sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB.getTime() - dateA.getTime(); // Descending order (newest first)
      })
      // Filter out any posts that don't have a valid page property
      .filter(post => post.page && typeof post.page === 'string')
      // Take the first 3 posts
      .slice(0, 3);
  }, []);

  return (
    <div className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">Latest Articles</h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Stay informed with our latest research and insights
          </p>
        </div>

        <div className="mt-12 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {recentArticles.map((article: BlogMetadata) => ( // Use data from blogMetadata
            <div 
              key={article.id} // Use unique ID for key
              className="flex flex-col rounded-lg shadow-lg overflow-hidden bg-white cursor-pointer hover:shadow-xl transition-shadow duration-300"
              onClick={() => navigate(article.page as PageName)} // Cast to PageName type
            >
              <div className="flex-shrink-0">
                <img 
                  className="h-48 w-full object-cover transform hover:scale-105 transition-transform duration-300" 
                  src={article.image} 
                  alt={article.title}
                />
              </div>
              <div className="flex-1 p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-emerald-600 bg-emerald-50 inline-block px-3 py-1 rounded-full">
                    {article.category}
                  </p>
                  <h3 className="mt-2 text-xl font-semibold text-gray-900 hover:text-emerald-600 transition-colors duration-200">
                    {article.title}
                  </h3>
                  <p className="mt-3 text-base text-gray-500 line-clamp-3">{article.excerpt}</p> {/* Added line-clamp */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
