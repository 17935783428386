import React from 'react';
import { blogMetadataList } from '../../data/blogMetadata';
import { useNavigation } from '../../contexts/NavigationContext';
import { Calendar } from 'lucide-react';
import { PageName } from '../../utils/router';
import { BlogMetadata } from '../../types/blog';

interface MainBlogPostProps {
  post?: BlogMetadata; // Make post optional to maintain backward compatibility
}

export function MainBlogPost({ post }: MainBlogPostProps) {
  const { navigate } = useNavigation();
  // Use provided post or fall back to first post in the list
  const featuredPost = post || blogMetadataList[0];

  if (!featuredPost) {
    return <div className="mb-12">No featured post available.</div>; // Handle case where list is empty
  }

  return (
    <article 
      className="mb-12 group cursor-pointer"
      onClick={() => navigate(featuredPost.page as PageName)}
    >
      <img
        className="w-full h-64 md:h-96 object-cover rounded-xl mb-6 group-hover:opacity-90 transition-opacity"
        src={featuredPost.image}
        alt={featuredPost.title}
      />
      <div className="text-sm font-medium text-emerald-600 bg-emerald-50 inline-block px-3 py-1 rounded-full mb-2">
        {featuredPost.category}
      </div>
      <h1 className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-3 group-hover:text-emerald-700 transition-colors">
        {featuredPost.title}
      </h1>
      <div className="flex items-center text-sm text-gray-500 mb-4">
        <Calendar className="h-4 w-4 mr-1.5" />
        <span>{featuredPost.date}</span>
        <span className="mx-2">•</span>
        <span>{featuredPost.readTime} read</span>
        <span className="mx-2">•</span>
        <span>By {featuredPost.author}</span>
      </div>
      <p className="text-lg text-gray-600 line-clamp-3">
        {featuredPost.excerpt}
      </p>
    </article>
  );
}
