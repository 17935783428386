import React from 'react';
import { useNavigation } from '../../contexts/NavigationContext';
import { Calendar } from 'lucide-react';
import { PageName } from '../../utils/router';
import { BlogMetadata } from '../../types/blog';

interface RelatedPostsProps {
  currentPostId: string;
  currentCategory: string;
  currentTags: string[];
  posts: BlogMetadata[];
  maxPosts?: number;
}

export function RelatedPosts({ 
  currentPostId, 
  currentCategory, 
  currentTags, 
  posts, 
  maxPosts = 3 
}: RelatedPostsProps) {
  const { navigate } = useNavigation();

  // Find related posts based on category and tags
  const relatedPosts = React.useMemo(() => {
    // Filter out the current post
    const otherPosts = posts.filter(post => post.id !== currentPostId);
    
    // Score each post based on relevance
    const scoredPosts = otherPosts.map(post => {
      let score = 0;
      
      // Same category is a strong signal
      if (post.category === currentCategory) {
        score += 5;
      }
      
      // Count matching tags
      const matchingTags = post.tags.filter(tag => currentTags.includes(tag));
      score += matchingTags.length * 2;
      
      return { post, score };
    });
    
    // Sort by score (highest first) and take the top N
    return scoredPosts
      .sort((a, b) => b.score - a.score)
      .slice(0, maxPosts)
      .map(item => item.post);
  }, [currentPostId, currentCategory, currentTags, posts, maxPosts]);

  if (relatedPosts.length === 0) {
    return null;
  }

  return (
    <div className="mt-12 pt-8 border-t border-gray-200">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">You Might Also Like</h2>
      <div className="grid gap-6 md:grid-cols-3">
        {relatedPosts.map((post) => (
          <article 
            key={post.id}
            onClick={() => navigate(post.page as PageName)}
            className="bg-white rounded-lg shadow overflow-hidden hover:shadow-md transition-shadow duration-300 cursor-pointer"
          >
            <img
              className="h-40 w-full object-cover"
              src={post.image}
              alt={post.title}
            />
            <div className="p-4">
              <div className="text-xs font-medium text-emerald-600 bg-emerald-50 inline-block px-2 py-1 rounded-full">
                {post.category}
              </div>
              <h3 className="mt-2 text-base font-semibold text-gray-900 hover:text-emerald-600 transition-colors duration-200 line-clamp-2">
                {post.title}
              </h3>
              <div className="mt-2 flex items-center text-xs text-gray-500">
                <Calendar className="h-3 w-3 mr-1" />
                <span>{post.date}</span>
              </div>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
}
