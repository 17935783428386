import React from 'react';

interface AffiliateDisclosureProps {
  variant?: 'inline' | 'block';
}

export function AffiliateDisclosure({ variant = 'block' }: AffiliateDisclosureProps) {
  if (variant === 'inline') {
    return (
      <span className="text-sm text-gray-500">
        As an Amazon Associate, we earn from qualifying purchases. This site also uses Google AdSense for advertising.
      </span>
    );
  }

  return (
    <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 text-sm text-gray-600">
      <p className="mb-2">
        PawfectChow is a participant in the Amazon Services LLC Associates Program, 
        an affiliate advertising program designed to provide a means for sites to earn 
        advertising fees by advertising and linking to Amazon.com. As an Amazon Associate, 
        we earn from qualifying purchases.
      </p>
      <p>
        This website also displays advertisements through Google AdSense, a program that allows 
        website publishers to earn revenue by displaying targeted ads on their content. These ads 
        may be based on your browsing behavior and interests.
      </p>
    </div>
  );
}
