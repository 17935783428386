import React, { useState, useEffect } from 'react';
// Removed unused import: import { useParams } from 'react-router-dom';
import { BackToBlog } from '../components/blog/BackToBlog';
import { BlogPostLayout } from '../components/layouts/BlogPostLayout';
import { ArticleWithAds } from '../components/layouts/ArticleWithAds';
import { AccordionFAQ, FAQItem } from '../components/blog/AccordionFAQ';
import { SourcesList } from '../components/blog/SourcesList';
import { RelatedPosts } from '../components/blog/RelatedPosts';
import { processBlogContent } from '../utils/contentProcessor';
import { blogMetadataList } from '../data/blogMetadata'; // Import from the new metadata file
import { BlogMetadata, Source } from '../types/blog'; // Import the BlogMetadata interface from types

// Use Vite's glob import to load all blog HTML content as raw strings
// Note: The path is relative to the project root.
// Updated glob options based on Vite deprecation warning
const blogContents = import.meta.glob('/src/data/blog-content/*.html', { query: '?raw', import: 'default', eager: true });

interface BlogPostPageProps {
  postId: string; // Pass the ID as a prop
}

export function BlogPostPage({ postId }: BlogPostPageProps) {
  const [postMetadata, setPostMetadata] = useState<BlogMetadata | null>(null);
  const [postContent, setPostContent] = useState<string>('');
  const [faqs, setFaqs] = useState<FAQItem[]>([]);
  const [sources, setSources] = useState<Source[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const metadata = blogMetadataList.find((post: BlogMetadata) => post.id === postId);
    setPostMetadata(metadata || null);
  }, [postId]);

  useEffect(() => {
    if (postMetadata?.contentPath) {
      setLoading(true);
      // Construct the key expected by the glob import result (relative to root)
      const globKey = `/${postMetadata.contentPath}`; 
      
      if (blogContents[globKey]) {
        // Process the content to extract FAQs and sources
        const rawContent = blogContents[globKey] as string;
        const { processedContent, faqs, sources: extractedSources } = processBlogContent(rawContent);
        
        setPostContent(processedContent);
        setFaqs(faqs);
        setSources(extractedSources);
        setLoading(false);
      } else {
        console.error(`Content not found in glob import for key: ${globKey}`);
        setError(`Failed to load content for ${postId}. Content file not found in build.`);
        setLoading(false);
      }
    } else if (postId && !postMetadata) { // Check if metadata itself wasn't found earlier
        // Error already set in the first useEffect if metadata is null after initial check
        // setError(`Blog post metadata not found for ID: ${postId}`); 
        setLoading(false); // Ensure loading stops if metadata is missing
    }
  }, [postMetadata, postId]);

  if (error) {
    return <BlogPostLayout><div>Error: {error}</div></BlogPostLayout>;
  }

  if (loading || !postMetadata) {
    return <BlogPostLayout><div>Loading post...</div></BlogPostLayout>;
  }

  return (
    <BlogPostLayout>
      <div>
        <BackToBlog />
        <div className="mb-8">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-4">{postMetadata.title}</h1>
          <div className="flex items-center text-sm text-gray-500 mb-4">
            <span className="mr-4">{postMetadata.date}</span>
            <span className="mr-4">•</span>
            <span className="mr-4">{postMetadata.readTime} read</span>
            <span className="mr-4">•</span>
            <span>By {postMetadata.author}</span>
          </div>
          <div className="text-sm font-medium text-emerald-600 bg-emerald-50 inline-block px-3 py-1 rounded-full mb-4">
            {postMetadata.category}
          </div>
          <div>
            {postMetadata.tags.map((tag: string) => (
              <span key={tag} className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-emerald-600 bg-emerald-200 last:mr-0 mr-1">
                {tag}
              </span>
            ))}
          </div>
          <img
            className="w-full h-64 md:h-96 object-cover rounded-xl mt-6"
            src={postMetadata.image}
            alt={postMetadata.title}
          />
        </div>
      </div>
      <ArticleWithAds>
        {postContent ? (
          <>
            <div
              className="prose prose-emerald max-w-none"
              dangerouslySetInnerHTML={{ __html: postContent }}
            />
            
            {/* FAQ Accordion */}
            {faqs.length > 0 && (
              <AccordionFAQ faqs={faqs} />
            )}
            
            {/* Sources List */}
            <SourcesList sources={sources} />
            
            {/* Related Posts */}
            <RelatedPosts 
              currentPostId={postMetadata.id}
              currentCategory={postMetadata.category}
              currentTags={postMetadata.tags}
              posts={blogMetadataList}
              maxPosts={3}
            />
          </>
        ) : (
          <p>Loading content...</p>
        )}
      </ArticleWithAds>
    </BlogPostLayout>
  );
}
