import React from 'react';
import { AffiliateDisclosure } from '../components/common/AffiliateDisclosure';

export function TermsOfService() {
  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-8">Terms of Service</h1>
        
        <div className="prose prose-emerald max-w-none">
          <p className="text-gray-600 mb-8">Last updated: April 2, 2025</p>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Agreement to Terms</h2>
            <p className="text-gray-600 mb-4">
              By accessing or using PawfectChow, you agree to be bound by these Terms of Service. 
              If you disagree with any part of the terms, you may not access the website.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Intellectual Property</h2>
            <p className="text-gray-600 mb-4">
              The website and its original content, features, and functionality are owned by PawfectChow 
              and are protected by international copyright, trademark, patent, trade secret, and other 
              intellectual property or proprietary rights laws.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">3. User Content</h2>
            <p className="text-gray-600 mb-4">
              Our website may allow you to post, link, store, share and otherwise make available certain 
              information, text, graphics, videos, or other material. You are responsible for the content 
              you post and its legality.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Accuracy of Information</h2>
            <p className="text-gray-600 mb-4">
              We make every effort to provide accurate and up-to-date information about dog nutrition and 
              pet food products. However, we do not warrant that such information is always accurate, 
              complete, or current. Any reliance on such information is at your own risk.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Advertising and Affiliate Disclosures</h2>
            <div className="mb-4">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">Google AdSense</h3>
              <p className="text-gray-600 mb-4">
                PawfectChow uses Google AdSense, a third-party advertising service, to display advertisements on our website. 
                These ads may be based on your browsing behavior and interests. Google AdSense uses cookies and similar 
                technologies to serve ads based on your prior visits to our website or other websites.
              </p>
              <p className="text-gray-600 mb-4">
                Google's use of advertising cookies enables it and its partners to serve ads to you based on your visit to our 
                site and/or other sites on the Internet. You may opt out of personalized advertising by visiting 
                <a href="https://www.google.com/settings/ads" target="_blank" rel="noopener noreferrer" className="text-emerald-600 hover:text-emerald-500"> Google Ad Settings</a>.
              </p>
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Amazon Associates Program</h3>
            <AffiliateDisclosure />
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Limitation of Liability</h2>
            <p className="text-gray-600 mb-4">
              PawfectChow shall not be liable for any indirect, incidental, special, consequential, or 
              punitive damages resulting from your access to or use of, or inability to access or use, 
              the website or any content on the website.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Changes to Terms</h2>
            <p className="text-gray-600 mb-4">
              We reserve the right to modify or replace these Terms at any time. If a revision is material, 
              we will try to provide at least 30 days' notice prior to any new terms taking effect.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Contact Information</h2>
            <p className="text-gray-600">
              If you have any questions about these Terms, please contact us at:{' '}
              <a href="mailto:contact@pawfectchow.com" className="text-emerald-600 hover:text-emerald-500">
                contact@pawfectchow.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}
