import React from 'react';
import { ExternalLink } from 'lucide-react';
import { Source } from '../../types/blog';

interface SourcesListProps {
  sources: Source[];
  title?: string;
}

export function SourcesList({ sources, title = "Sources" }: SourcesListProps) {
  if (!sources || sources.length === 0) {
    return null;
  }

  return (
    <div className="mt-8 mb-8 bg-gray-50 p-6 rounded-lg border border-gray-200">
      <h2 className="text-2xl font-bold text-gray-900 mb-4">{title}</h2>
      <ul className="space-y-3">
        {sources.map((source, index) => (
          <li key={index} className="flex">
            <a 
              href={source.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-emerald-600 hover:text-emerald-800 underline flex items-start group"
            >
              <span className="inline-block mr-2">
                <ExternalLink size={16} className="mt-1 opacity-70 group-hover:opacity-100" />
              </span>
              <span>
                {source.title}
                {(source.author || source.publication || source.date) && (
                  <span className="text-gray-600 block text-sm">
                    {source.author && <span>{source.author}</span>}
                    {source.author && source.publication && <span>, </span>}
                    {source.publication && <span>{source.publication}</span>}
                    {(source.author || source.publication) && source.date && <span>, </span>}
                    {source.date && <span>{source.date}</span>}
                  </span>
                )}
              </span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
