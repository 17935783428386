import React, { useEffect } from 'react';

// Add type declaration for window.adsbygoogle
declare global {
  interface Window {
    adsbygoogle?: any[];
  }
}
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { NavigationProvider } from './contexts/NavigationContext';
import { Home } from './pages/Home';
import { FoodTypes } from './pages/FoodTypes';
import { LifeStages } from './pages/LifeStages';
import { SpecialNeeds } from './pages/SpecialNeeds';
import { Brands } from './pages/Brands';
import { BlogPost } from './pages/BlogPost'; // This is the blog list page
import { BlogPostPage } from './pages/BlogPostPage'; // Import the new generic component
// Removed imports for individual blog post components
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService';
import { CookieConsent } from './components/common/CookieConsent';
import { updateMetaTags } from './utils/seo';
import { initializeRouter, PageName } from './utils/router';

export default function App() {
  const [currentPage, setCurrentPage] = React.useState<PageName>('home');

  useEffect(() => {
    initializeRouter(setCurrentPage);
  }, []);

  useEffect(() => {
    updateMetaTags(currentPage);
    // Note: Manual ad initialization removed
    // We're using Auto Ads from the script in index.html
  }, [currentPage]);

  // Initialize AdSense with proper configuration
  useEffect(() => {
    // Check if the user has opted out of personalized ads
    const nonPersonalizedAds = localStorage.getItem('non-personalized-ads') === 'true';
    
    // Initialize AdSense with the appropriate configuration
    try {
      if (window.adsbygoogle && Array.isArray(window.adsbygoogle)) {
        window.adsbygoogle.push({
          google_ad_client: "ca-pub-2369126462299626",
          enable_page_level_ads: true,
          tag_for_child_directed_treatment: nonPersonalizedAds ? 1 : 0
        });
      }
    } catch (e) {
      console.error('Error initializing AdSense:', e);
    }
  }, []);

  return (
    <NavigationProvider onNavigate={setCurrentPage}>
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Header />
        <main className="flex-grow">
          {currentPage === 'home' && <Home />}
          {currentPage === 'food-types' && <FoodTypes />}
          {currentPage === 'life-stages' && <LifeStages />}
          {currentPage === 'special-needs' && <SpecialNeeds />}
          {currentPage === 'brands' && <Brands />}
          {currentPage === 'blog' && <BlogPost />} 
          {/* Render generic blog post page if currentPage starts with 'blog-' */}
          {currentPage.startsWith('blog-') && currentPage !== 'blog' && (
            <BlogPostPage postId={currentPage.substring(5)} />
          )}
          {currentPage === 'privacy-policy' && <PrivacyPolicy />}
          {currentPage === 'terms-of-service' && <TermsOfService />}
        </main>
        <Footer />
        <CookieConsent />
      </div>
    </NavigationProvider>
  );
}
