// Map paths to page names and their corresponding static HTML files
export const ROUTES = {
  '/': { page: 'home', file: 'index.html' },
  '/food-types': { page: 'food-types', file: 'food-types/index.html' },
  '/life-stages': { page: 'life-stages', file: 'life-stages/index.html' },
  '/special-needs': { page: 'special-needs', file: 'special-needs/index.html' },
  '/brands': { page: 'brands', file: 'brands/index.html' },
  '/blog': { page: 'blog', file: 'blog/index.html' },
  '/blog-food-labels': { page: 'blog-food-labels', file: 'blog/food-labels/index.html' },
  '/blog-grain-free': { page: 'blog-grain-free', file: 'blog/grain-free/index.html' },
  '/blog-senior-dogs': { page: 'blog-senior-dogs', file: 'blog/senior-dogs/index.html' },
  '/blog-the-complete-guide-to-balanced-dog-nutrition-what': { page: 'blog-the-complete-guide-to-balanced-dog-nutrition-what', file: 'blog/the-complete-guide-to-balanced-dog-nutrition-what/index.html' },
  '/blog-understanding-cat-nutrition-essential-nutrients-fo': { page: 'blog-understanding-cat-nutrition-essential-nutrients-fo', file: 'blog/understanding-cat-nutrition-essential-nutrients-fo/index.html' },
  '/blog-protein-sources-in-pet-food-a-comprehensive-compar': { page: 'blog-protein-sources-in-pet-food-a-comprehensive-compar', file: 'blog/protein-sources-in-pet-food-a-comprehensive-compar/index.html' },
  '/blog-carbohydrates-in-pet-food-friend-or-foe': { page: 'blog-carbohydrates-in-pet-food-friend-or-foe', file: 'blog/carbohydrates-in-pet-food-friend-or-foe/index.html' },
  '/blog-essential-fatty-acids-why-your-pet-needs-omega-3-a': { page: 'blog-essential-fatty-acids-why-your-pet-needs-omega-3-a', file: 'blog/essential-fatty-acids-why-your-pet-needs-omega-3-a/index.html' },
  '/blog-how-proper-nutrition-can-extend-your-pet-s-lifespa': { page: 'blog-how-proper-nutrition-can-extend-your-pet-s-lifespa', file: 'blog/how-proper-nutrition-can-extend-your-pet-s-lifespa/index.html' },
  '/blog-the-connection-between-diet-and-your-pet-s-coat-he': { page: 'blog-the-connection-between-diet-and-your-pet-s-coat-he', file: 'blog/the-connection-between-diet-and-your-pet-s-coat-he/index.html' },
  '/blog-nutrition-for-joint-health-foods-that-fight-arthri': { page: 'blog-nutrition-for-joint-health-foods-that-fight-arthri', file: 'blog/nutrition-for-joint-health-foods-that-fight-arthri/index.html' },
  '/blog-digestive-health-choosing-the-right-food-for-sensi': { page: 'blog-digestive-health-choosing-the-right-food-for-sensi', file: 'blog/digestive-health-choosing-the-right-food-for-sensi/index.html' },
  '/blog-the-impact-of-diet-on-your-pet-s-dental-health': { page: 'blog-the-impact-of-diet-on-your-pet-s-dental-health', file: 'blog/the-impact-of-diet-on-your-pet-s-dental-health/index.html' },
  '/blog-grain-free-diets-benefits-and-considerations-for-y': { page: 'blog-grain-free-diets-benefits-and-considerations-for-y', file: 'blog/grain-free-diets-benefits-and-considerations-for-y/index.html' },
  '/blog-raw-food-diets-a-comprehensive-guide-for-dog-owner': { page: 'blog-raw-food-diets-a-comprehensive-guide-for-dog-owner', file: 'blog/raw-food-diets-a-comprehensive-guide-for-dog-owner/index.html' },
  '/blog-hypoallergenic-pet-foods-solutions-for-allergic-pe': { page: 'blog-hypoallergenic-pet-foods-solutions-for-allergic-pe', file: 'blog/hypoallergenic-pet-foods-solutions-for-allergic-pe/index.html' },
  '/blog-low-carb-diets-for-diabetic-pets-what-you-need-to': { page: 'blog-low-carb-diets-for-diabetic-pets-what-you-need-to', file: 'blog/low-carb-diets-for-diabetic-pets-what-you-need-to/index.html' },
  '/blog-weight-management-nutrition-plans-for-overweight-p': { page: 'blog-weight-management-nutrition-plans-for-overweight-p', file: 'blog/weight-management-nutrition-plans-for-overweight-p/index.html' },
  '/blog-nutritional-needs-of-large-breed-dogs-from-puppyho': { page: 'blog-nutritional-needs-of-large-breed-dogs-from-puppyho', file: 'blog/nutritional-needs-of-large-breed-dogs-from-puppyho/index.html' },
  '/blog-small-breed-nutrition-meeting-the-unique-needs-of': { page: 'blog-small-breed-nutrition-meeting-the-unique-needs-of', file: 'blog/small-breed-nutrition-meeting-the-unique-needs-of/index.html' },
  '/blog-brachycephalic-breeds-special-dietary-consideratio': { page: 'blog-brachycephalic-breeds-special-dietary-consideratio', file: 'blog/brachycephalic-breeds-special-dietary-consideratio/index.html' },
  '/blog-nutrition-for-high-energy-working-dogs-fueling-per': { page: 'blog-nutrition-for-high-energy-working-dogs-fueling-per', file: 'blog/nutrition-for-high-energy-working-dogs-fueling-per/index.html' },
  '/blog-breed-specific-health-issues-and-how-diet-can-help': { page: 'blog-breed-specific-health-issues-and-how-diet-can-help', file: 'blog/breed-specific-health-issues-and-how-diet-can-help/index.html' },
  '/blog-puppy-nutrition-building-blocks-for-a-healthy-life': { page: 'blog-puppy-nutrition-building-blocks-for-a-healthy-life', file: 'blog/puppy-nutrition-building-blocks-for-a-healthy-life/index.html' },
  '/blog-senior-pet-nutrition-adapting-diet-as-your-pet-age': { page: 'blog-senior-pet-nutrition-adapting-diet-as-your-pet-age', file: 'blog/senior-pet-nutrition-adapting-diet-as-your-pet-age/index.html' },
  '/blog-nutrition-during-pregnancy-supporting-mother-dogs': { page: 'blog-nutrition-during-pregnancy-supporting-mother-dogs', file: 'blog/nutrition-during-pregnancy-supporting-mother-dogs/index.html' },
  '/blog-transitioning-from-puppy-to-adult-food-when-and-ho': { page: 'blog-transitioning-from-puppy-to-adult-food-when-and-ho', file: 'blog/transitioning-from-puppy-to-adult-food-when-and-ho/index.html' },
  '/blog-nutrition-for-geriatric-pets-supporting-quality-of': { page: 'blog-nutrition-for-geriatric-pets-supporting-quality-of', file: 'blog/nutrition-for-geriatric-pets-supporting-quality-of/index.html' },
  '/blog-fish-oil-benefits-for-dogs-improving-skin-coat-and': { page: 'blog-fish-oil-benefits-for-dogs-improving-skin-coat-and', file: 'blog/fish-oil-benefits-for-dogs-improving-skin-coat-and/index.html' },
  '/blog-probiotics-for-pets-enhancing-gut-health-and-immun': { page: 'blog-probiotics-for-pets-enhancing-gut-health-and-immun', file: 'blog/probiotics-for-pets-enhancing-gut-health-and-immun/index.html' },
  '/blog-joint-supplements-glucosamine-chondroitin-and-msm': { page: 'blog-joint-supplements-glucosamine-chondroitin-and-msm', file: 'blog/joint-supplements-glucosamine-chondroitin-and-msm/index.html' },
  '/blog-vitamin-supplements-when-are-they-necessary-for-yo': { page: 'blog-vitamin-supplements-when-are-they-necessary-for-yo', file: 'blog/vitamin-supplements-when-are-they-necessary-for-yo/index.html' },
  '/blog-natural-supplements-for-pet-anxiety-and-stress-rel': { page: 'blog-natural-supplements-for-pet-anxiety-and-stress-rel', file: 'blog/natural-supplements-for-pet-anxiety-and-stress-rel/index.html' },
  '/blog-decoding-pet-food-labels-what-those-ingredients-re': { page: 'blog-decoding-pet-food-labels-what-those-ingredients-re', file: 'blog/decoding-pet-food-labels-what-those-ingredients-re/index.html' },
  '/blog-superfoods-for-pets-nutrient-dense-additions-to-yo': { page: 'blog-superfoods-for-pets-nutrient-dense-additions-to-yo', file: 'blog/superfoods-for-pets-nutrient-dense-additions-to-yo/index.html' },
  '/blog-controversial-ingredients-in-pet-food-separating-f': { page: 'blog-controversial-ingredients-in-pet-food-separating-f', file: 'blog/controversial-ingredients-in-pet-food-separating-f/index.html' },
  '/blog-novel-proteins-benefits-of-exotic-meat-sources-in': { page: 'blog-novel-proteins-benefits-of-exotic-meat-sources-in', file: 'blog/novel-proteins-benefits-of-exotic-meat-sources-in/index.html' },
  '/blog-preservatives-in-pet-food-the-good-the-bad-and-the': { page: 'blog-preservatives-in-pet-food-the-good-the-bad-and-the', file: 'blog/preservatives-in-pet-food-the-good-the-bad-and-the/index.html' },
  '/blog-summer-nutrition-keeping-your-pet-hydrated-and-hea': { page: 'blog-summer-nutrition-keeping-your-pet-hydrated-and-hea', file: 'blog/summer-nutrition-keeping-your-pet-hydrated-and-hea/index.html' },
  '/blog-winter-diet-adjustments-should-you-feed-more-in-co': { page: 'blog-winter-diet-adjustments-should-you-feed-more-in-co', file: 'blog/winter-diet-adjustments-should-you-feed-more-in-co/index.html' },
  '/blog-seasonal-allergies-how-diet-can-help-manage-sympto': { page: 'blog-seasonal-allergies-how-diet-can-help-manage-sympto', file: 'blog/seasonal-allergies-how-diet-can-help-manage-sympto/index.html' },
  '/blog-holiday-food-safety-protecting-your-pet-during-fes': { page: 'blog-holiday-food-safety-protecting-your-pet-during-fes', file: 'blog/holiday-food-safety-protecting-your-pet-during-fes/index.html' },
  '/blog-seasonal-ingredients-incorporating-fresh-seasonal': { page: 'blog-seasonal-ingredients-incorporating-fresh-seasonal', file: 'blog/seasonal-ingredients-incorporating-fresh-seasonal/index.html' },
  '/privacy-policy': { page: 'privacy-policy', file: 'privacy-policy/index.html' },
  '/terms-of-service': { page: 'terms-of-service', file: 'terms-of-service/index.html' }
} as const;

export type RoutePath = keyof typeof ROUTES;
export type PageName = typeof ROUTES[RoutePath]['page'];

// Get normalized path
function getNormalizedPath(): RoutePath {
  const path = window.location.pathname.replace(/\/$/, '') || '/';
  return path as RoutePath;
}

// Initialize router
export function initializeRouter(setPage: (page: PageName) => void) {
  // Handle initial route
  const path = getNormalizedPath();
  const initialPage = ROUTES[path]?.page || 'home';
  setPage(initialPage);

  // Handle browser back/forward
  window.addEventListener('popstate', () => {
    const currentPath = getNormalizedPath();
    const page = ROUTES[currentPath]?.page || 'home';
    setPage(page);
  });
}

// Navigate to page
export function navigateTo(path: RoutePath, setPage: (page: PageName) => void) {
  // Get the route configuration
  const route = ROUTES[path];
  if (!route) return;

  // Update URL and page state
  window.history.pushState({}, '', path);
  setPage(route.page);
}

// Get route path from page name
export function getRoutePathFromPage(pageName: PageName): RoutePath {
  const route = Object.entries(ROUTES).find(([_, config]) => config.page === pageName);
  return route ? (route[0] as RoutePath) : '/';
}
