import React from 'react';

export function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-8">Privacy Policy</h1>
        
        <div className="prose prose-emerald max-w-none">
          <p className="text-gray-600 mb-8">Last updated: April 2, 2025</p>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Information We Collect</h2>
            <p className="text-gray-600 mb-4">We collect information that you provide directly to us, including:</p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>Usage data and analytics through Google Analytics</li>
              <li>Search queries and interactions with our website</li>
              <li>Browser type and version</li>
              <li>Operating system</li>
              <li>Referral source</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">2. How We Use Your Information</h2>
            <p className="text-gray-600 mb-4">We use the information we collect to:</p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>Improve and optimize our website</li>
              <li>Analyze user behavior and preferences</li>
              <li>Provide personalized content recommendations</li>
              <li>Monitor and analyze trends and usage</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Cookies and Tracking Technologies</h2>
            <p className="text-gray-600 mb-4">
              We use cookies and similar tracking technologies to track activity on our website and hold certain information. 
              Cookies are files with small amount of data which may include an anonymous unique identifier.
            </p>
            <p className="text-gray-600 mb-4">
              We use the following types of cookies:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>Essential cookies for website functionality</li>
              <li>Analytics cookies (Google Analytics)</li>
              <li>Preference cookies</li>
              <li>Advertising cookies (Google AdSense)</li>
            </ul>
            <p className="text-gray-600 mb-4">
              We partner with Google AdSense to display advertisements on our website. Google uses cookies 
              to help serve the ads it displays on our website. When users visit our website, a cookie 
              may be dropped on that end user's browser.
            </p>
            
            <h3 className="text-xl font-bold text-gray-900 mb-2">Google AdSense & Advertising Data</h3>
            <p className="text-gray-600 mb-4">
              Google AdSense may collect the following data for advertising purposes:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>IP address</li>
              <li>Cookie identifiers</li>
              <li>Device identifiers</li>
              <li>Client identifiers</li>
              <li>Browsing and interaction data</li>
              <li>Page view information</li>
              <li>Geographic location (country level)</li>
              <li>Language preferences</li>
            </ul>
            
            <p className="text-gray-600 mb-4">
              Specifically:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>Third party vendors, including Google, use cookies to serve ads based on a user's prior visits to our website or other websites.</li>
              <li>Google's use of advertising cookies enables it and its partners to serve ads to our users based on their visit to our sites and/or other sites on the Internet.</li>
              <li>Users may opt out of personalized advertising by visiting <a href="https://www.google.com/settings/ads" target="_blank" rel="noopener noreferrer" className="text-emerald-600 hover:text-emerald-500">Google Ad Settings</a>.</li>
              <li>Alternatively, users can opt out of a third-party vendor's use of cookies for personalized advertising by visiting <a href="https://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer" className="text-emerald-600 hover:text-emerald-500">www.aboutads.info/choices</a>.</li>
            </ul>
            
            <h3 className="text-xl font-bold text-gray-900 mb-2">Cookie Management</h3>
            <p className="text-gray-600 mb-4">
              You can manage your cookie preferences through our cookie consent banner that appears when you first visit our site. You can choose to:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li><strong>Accept all cookies</strong>: This allows all cookies, including those used for analytics and advertising.</li>
              <li><strong>Decline</strong>: This will disable personalized advertising, though you may still see non-personalized ads.</li>
            </ul>
            <p className="text-gray-600 mb-4">
              You can also manage cookies through your browser settings. Most browsers allow you to block or delete cookies.
            </p>
            
            <p className="text-gray-600 mb-4">
              For more information on how Google uses data when you use our partners' sites or apps, see 
              <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer" className="text-emerald-600 hover:text-emerald-500"> How Google uses data when you use our partners' sites or apps</a>.
            </p>
            <p className="text-gray-600 mb-4">
              For a complete overview of Google's privacy practices, please review <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" className="text-emerald-600 hover:text-emerald-500">Google's Privacy Policy</a>.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Third-Party Services</h2>
            <p className="text-gray-600 mb-4">
              We use third-party services that may collect information used to identify you:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>Google Analytics for website analytics</li>
              <li>Amazon Associates Program for affiliate marketing</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Data Security</h2>
            <p className="text-gray-600 mb-4">
              The security of your data is important to us, but remember that no method of transmission over 
              the Internet, or method of electronic storage is 100% secure.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Changes to This Privacy Policy</h2>
            <p className="text-gray-600 mb-4">
              We may update our Privacy Policy from time to time. We will notify you of any changes by 
              posting the new Privacy Policy on this page and updating the "Last updated" date.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Contact Us</h2>
            <p className="text-gray-600">
              If you have any questions about this Privacy Policy, please contact us at:{' '}
              <a href="mailto:contact@pawfectchow.com" className="text-emerald-600 hover:text-emerald-500">
                contact@pawfectchow.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}
