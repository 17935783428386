import React, { useState, useEffect } from 'react';

export function CookieConsent() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if user has already consented
    const hasConsented = localStorage.getItem('cookie-consent');
    if (!hasConsented) {
      // Show banner if no consent has been given
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    // Store consent in localStorage
    localStorage.setItem('cookie-consent', 'true');
    localStorage.setItem('non-personalized-ads', 'false');
    
    // Reload the page to apply the consent settings
    window.location.reload();
  };

  const handleDecline = () => {
    // Store decline in localStorage
    localStorage.setItem('cookie-consent', 'false');
    localStorage.setItem('non-personalized-ads', 'true');
    
    // Reload the page to apply the consent settings
    window.location.reload();
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 z-50">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="mb-4 md:mb-0 md:mr-4">
          <p className="text-sm md:text-base">
            This website uses cookies to enhance your experience, analyze our traffic, and display personalized ads through Google AdSense. 
            By clicking "Accept", you consent to our use of cookies as described in our{' '}
            <a href="/privacy-policy" className="text-emerald-400 hover:text-emerald-300 underline">
              Privacy Policy
            </a>. You can opt out of personalized advertising by visiting{' '}
            <a href="https://www.google.com/settings/ads" target="_blank" rel="noopener noreferrer" className="text-emerald-400 hover:text-emerald-300 underline">
              Google Ad Settings
            </a>.
          </p>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={handleDecline}
            className="px-4 py-2 bg-gray-600 hover:bg-gray-700 rounded text-sm md:text-base"
          >
            Decline
          </button>
          <button
            onClick={handleAccept}
            className="px-4 py-2 bg-emerald-600 hover:bg-emerald-700 rounded text-sm md:text-base"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
}
